<template>
  <base-section
    id="loan-products"
    class="blue-grey darken-2"
  >
    <base-section-heading title="Loan Products"></base-section-heading>
    <v-container>
      <v-row>
        <v-col
          v-for="(product, i) in products"
          :key="i"
          xl="3"
          lg="4"
          md="6"
          sm="6"
        >
          <loan-product
            :title="product.title"
            :desc="product.desc"
            :image="product.image?product.image:'forest-art.jpg'"
            :lines="product.lines"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  // import * as api from '@/functions/api.js'

  export default {
    name: 'SectionThemeFeatures',
    components: {
      LoanProduct: () => import('@/components/LoanProduct'),
    },
    data: () => ({
      products: [
        {
          title: 'Rehab Loans',
          icon: 'mdi-cellphone',
          image: 'Rehab-375x225.jpg',
          lines: [
            { text: 'Used to purchase and renovate property' },
            { text: 'First time investors okay' },
            { text: 'Potentially 100% finance' },
            { text: '6-12 mo interest only loans' },
            { text: 'Can Close in as little as 10 Business Days' },
          ],
          desc: ' Rehab Loans',
        },
        {
          title: 'Rental Loans',
          icon: 'mdi-fountain-pen-tip',
          image: 'Rental-375x225.jpg',
          lines: [
            { text: '30 yr fixed rate loans available' },
            { text: 'Qualify based on subject property cashflow, not personal income' },
            { text: 'No cap on number of properties financed' },
            { text: 'Blanket loans available out to 30 yr fixed rate' },
          ],
          desc: 'Rental Loans',
        },
        {
          color: 'primary',
          dark: true,
          title: 'Apartment Lending',
          icon: 'mdi-pencil-box-outline',
          image: 'Apartment-375x225.jpg',
          lines: [
            { text: 'Up to 80% LTV' },
            { text: 'Up to 30 yr fixed rate' },
            { text: 'Bridge and stabilized available' },
            { text: 'Lite Doc and full Doc available' },
          ],
          desc: 'Apartments',
        },
        {
          color: 'primary',
          dark: true,
          title: 'Commercial Property',
          icon: 'mdi-pencil-box-outline',
          image: 'Commercial-Property-375x225.jpg',
          lines: [
            { text: 'Up to 80% LTV' },
            { text: 'Owner Occupied or Investment' },
            { text: 'Min Loan amount 250k' },
            { text: 'Bridge and Stabilized available' },
            { text: 'Development Loans Available' },
          ],
          desc: 'Commercial Property',
        },
        {
          title: 'Development Loans',
          icon: 'mdi-image-size-select-actual',
          image: 'AgricultureLandLoan-375x225.jpg',
          lines: [
            { text: 'Min 30 acres' },
            { text: 'Min loan 100k' },
            { text: 'As little as 20-25% down' },
            { text: 'Potentially 100% of cost up to 65% of after repair value' },
            { text: 'Less Expensive options with down payment' },
          ],
          desc: 'Development Loans',
        },
        {
          title: 'SBA Lending',
          icon: 'mdi-ice-pop',
          image: 'SBA-375x225.jpg',
          lines: [
            { text: 'Startup or expansion funds for your small business' },
            { text: 'Potentially 100% financing on Real Estate for the business' },
            { text: 'Residential Care homes okay' },
          ],
          desc: 'SBA Lending',
        },
        {
          title: 'Cannabis Related Lending',
          icon: 'mdi-help-circle-outline',
          image: 'Cannabis-375x225.jpg',
          lines: [
            { text: 'Yes, you can get a loan' },
            { text: 'Can lend on the land and the building' },
            { text: 'Min loan 500k' },
            { text: 'Max Loan to Value 70%' },
          ],
          desc: 'Cannabis Related Lending',
        },
        {
          title: 'Co-Brokering',
          icon: 'mdi-help-circle-outline',
          image: 'Cobroker-375x225.jpg',
          lines: [
            { text: 'Broker Referrals welcome and paid' },
            { text: 'Contact Us for details' },
          ],
          desc: 'Co-Brokering',
        },
      ],
    }),
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
      },
    },
  }
</script>
<style lang="sass">
    .redborder
      border: 1px solid red
</style>
